import React from "react";
import { Link } from "react-router-dom";

import { useGlobalContext } from "./Context";

const NavbarLink = () => {
	const { logout, login } = useGlobalContext();

	return (
		login && (
			<nav className="NavBar">
				<Link to="/admin">Upload</Link>
				<Link to="/users">Users</Link>
				<Link to="/setting">Setting</Link>
				<Link className="link" onClick={() => logout()}>
					Logout
				</Link>
			</nav>
		)
	);
};

export default NavbarLink;
