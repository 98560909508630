import React from "react";
import FileList from "../Components/FileList";
import FileDropArea from "../Components/FileDropArea";
import logo from "../logo.png";
import NavbarLink from "../Components/NavbarLink";

const Panel = () => {
	return (
		<>
			<NavbarLink></NavbarLink>
			<div className="wrapperAdmin">
				<img src={logo} alt="Logo" className="logo"></img>
				<h1>* PANNELLO GESTIONE DOCUMENTI *</h1>
				<FileDropArea />
				<FileList></FileList>
			</div>
		</>
	);
};

export default Panel;
