const reducer = (state, { type, payload }) => {
	if (type === "UPLOAD") {
		const [nameFile, fetchFunction, setFiles] = payload;
		if (!nameFile) {
			alert("Seleziona un file da caricare.");
			return;
		}

		const formData = new FormData();
		formData.append("file", nameFile);

		let uploadLink = "/upload";
		if (process.env.NODE_ENV === "development") {
			uploadLink = "/upload";
		} else if (process.env.NODE_ENV === "production") {
			uploadLink = "/upload";
		} else {
			// Gestione dell'ambiente non riconosciuto
		}
		fetch(uploadLink, {
			method: "POST",
			body: formData,
		})
			.then((response) => {
				fetchFunction();
				setFiles(null);
				return {
					...state,
					user: { ...state.user },
					fileInputRef: null,
				};
			})
			.catch((error) => {
				console.error("Errore durante l'upload:", error);
			});
	} else if (type === "LIST_FILES") {
		(async () => {
			try {
				let uploadLink = "/listOfFiles";
				if (process.env.NODE_ENV === "development") {
					uploadLink = "/listOfFiles";
				} else if (process.env.NODE_ENV === "production") {
					uploadLink = "/listOfFiles";
				} else {
					// Gestione dell'ambiente non riconosciuto
				}
				const response = await fetch(uploadLink); // Sostituisci con l'URL corretto per la tua API
				if (response) {
					const data = await response.json();
					const setFileNames = payload[0];

					setFileNames(data);
					return {
						...state,
					};
				} else {
					console.error("Errore nella richiesta:", response.status);
				}
			} catch (error) {
				console.error("Errore durante la richiesta:", error);
			}
		})();
		return { ...state, filenames: "pippo" };
	} else if (type === "USERNAME") {
		return {
			...state,
			user: {
				...state.user,
				username: payload,
			},
		};
	} else if (type === "PASSWORD") {
		return {
			...state,
			user: {
				...state.user,
				password: payload,
			},
		};
	} else if (type === "LOGIN") {
		if (payload !== false) {
			localStorage.setItem("username", payload[0].nome);
			localStorage.setItem("role", payload[0].role);
			return {
				...state,
				login: true,
				errorAuth: false,
				user: {
					...state.user,
					role: payload[0].role,
					username: payload[0].nome,
				},
			};
		} else {
			return {
				...state,
				user: { ...state.user },
				login: payload,
				errorAuth: true,
			};
		}
	} else if (type === "ERROR_AUTH") {
		return { ...state, errorAuth: true };
	} else if (type === "SET_BY_LOCAL") {
		return {
			...state,
			login: true,
			user: {
				username: localStorage.getItem("username"),
				role: localStorage.getItem("role"),
				password: localStorage.getItem("role"),
			},
		};
	} else if (type === "LOGOUT") {
		localStorage.removeItem("username");
		localStorage.removeItem("role");
		return {
			...state,
			login: false,
			user: { username: "", password: "", role: "" },
		};
	} else if (type === "SET_FORM_VALUE") {
		const { name, value } = payload;

		let messageError = "";
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		const phoneRegex =
			/^(\+|00)?(39)?\s?(3[0-9]{2})(\s?\d{2}){2,3}|(?:\+|00)?(?:39\s?)?(?:3[0-9]{2})\s?(?:\d{3}\s?\d{2}\s?\d{2})$/;
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{6,10}$/;
		const isValidPhone = (phone) => {
			return phoneRegex.test(phone);
		};
		const isValidEmail = (email) => {
			return emailRegex.test(email);
		};
		const isValidPassword = (password) => {
			return passwordRegex.test(password);
		};
		if (name === "nome" && value.length < 3) {
			messageError = `Il campo ${name} deve avere almeno 3 caratteri`;
		} else if (name === "cognome" && value.length < 3) {
			messageError = `Il campo ${name} deve avere almeno 3 caratteri`;
		} else if (name === "email" && !isValidEmail(value)) {
			messageError = `Fornisci una ${name} valida`;
		} else if (name === "cellulare" && !isValidPhone(value)) {
			messageError = `Fornisci un numero di ${name} valido`;
		} else if (name === "password" && !isValidPassword(value)) {
			if (value.length >= 10) {
				messageError = `Max 10 caratteri`;
			} else {
				messageError = `Da 6 caratteri a 10 caratteri, almeno una lettera maiuscola, lettere e numeri e nessun carattere speciale`;
			}
		} else if (name === "confPass" && value !== state.regUser.password) {
			messageError = `Le password non corrispondono`;
		}

		return {
			...state,
			regUser: {
				...state.regUser,
				[name]: value,
			},
			messageError: {
				[name]: messageError,
			},
		};
	} else if (type === "REGVALID") {
		return {
			...state,
			regiStrationValid: payload,
		};
	} else if (type === "ERROR_MESSAGE") {
		console.log("qui");
		const [campo, errore] = payload;
		console.log(payload);
		return {
			...state,
			messageError: {
				[campo]: errore,
			},
		};
	} else if (type === "SET_USER_DATA") {
		localStorage.setItem("username", state.regUser.nome);
		localStorage.setItem("role", "user");

		return {
			...state,
			regiStrationValid: false,
			login: true,
			user: {
				...state.user,
				username: state.regUser.nome,
				role: "user",
			},
			messageError: {
				nome: null,
				cognome: null,
				email: null,
				cellulare: null,
				password: null,
				confPass: null,
			},
			messageServer: payload,
			regUser: {
				nome: "",
				cognome: "",
				email: "",
				cellulare: "",
				password: "",
				confPass: "",
			},
		};
	}
	return { ...state };
	throw new Error(`errore ${type}`);
};

export default reducer;
