import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import Panel from "./Screen/Panel";
import FormRegistration from "./Screen/FormRegistration";
import Login from "./Screen/Login";
import ErrorPage from "./Screen/ErrorPage";
import UserList from "./Screen/UserList";
import SingleUser from "./Screen/SingleUser";

import { useGlobalContext } from "./Components/Context";

function App() {
	const { login, user } = useGlobalContext();
	return (
		<>
			{" "}
			<Router>
				<Routes>
					<Route
						path="/admin"
						element={
							login && user.role === "admin" ? (
								<Panel />
							) : (
								<Navigate to="/login" />
							)
						}></Route>
					<Route
						path="/"
						element={
							login && user.role === "user" ? (
								window.location.replace("/")
							) : (
								<Navigate to="/login" />
							)
						}></Route>
					<Route path="/registration" element={<FormRegistration />} />
					{/* <Route
						path="/login"
						element={login ? <Navigate to="/admin" /> : <Login />}
					/> */}
					<Route
						path="/login"
						element={
							login ? (
								user.role === "admin" ? (
									<Navigate to="/admin" />
								) : (
									""
								)
							) : (
								<Login />
							)
						}
					/>
					<Route
						path="/users/:id"
						element={
							login && user.role === "admin" ? <SingleUser /> : <Login></Login>
						}></Route>
					<Route
						path="/users"
						element={
							login && user.role === "admin" ? <UserList /> : <Login></Login>
						}></Route>
					<Route path="*" element={<ErrorPage />}></Route>
				</Routes>
			</Router>
		</>
	);
}

export default App;
