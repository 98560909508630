/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useContext,
	useEffect,
	useReducer,
	useState,
	useRef,
} from "react";

import reducer from "./Reducer";

const AppContext = React.createContext();

const initialState = {
	welcome: true,
	fileInputRef: null,
	login: false,
	checkForm: false,
	errorAuth: false,
	messageUsername: "",
	messagePassword: "",
	messageError: {
		nome: null,
		cognome: null,
		email: null,
		cellulare: null,
		password: null,
		confPass: null,
	},
	messageServer: "",
	regiStrationValid: false,
	user: { username: "", password: "", role: "" },
	mode: process.env.NODE_ENV,
	regUser: {
		nome: "",
		cognome: "",
		email: "",
		cellulare: "",
		password: "",
		confPass: "",
	},
};

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [fileNames, setFileNames] = useState([]);
	const [files, setFiles] = useState();
	const fileInputRef = useRef();
	const { username, password } = state.user;

	/** REGISTRATION */
	const setValueRegistration = (e) => {
		dispatch({ state, type: "SET_FORM_VALUE", payload: e.target });
	};

	useEffect(() => {
		const isAllFieldsCompleted = Object.values(state.regUser).every(
			(value) => value !== null && value !== ""
		);
		const allValuesNull = Object.values(state.messageError).every(
			(value) => value === ""
		);

		if (isAllFieldsCompleted && allValuesNull) {
			dispatch({ state, type: "REGVALID", payload: true });
		} else {
			dispatch({ state, type: "REGVALID", payload: false });
		}
	}, [state.regUser]);

	const submitFormData = (event) => {
		// event.preventDefault();

		// Effettua la chiamata POST al server con i dati del form
		fetch("/newAccount", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(state.regUser),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.error === "ER_DUP_ENTRY") {
					console.log("error2");
					dispatch({
						state,
						type: "ERROR_MESSAGE",
						payload: ["email", "Email esistente"],
					});
				} else if (data.message === "ok") {
					dispatch({
						state,
						type: "SET_USER_DATA",
						payload: ["Dati inseriti correttamente"],
					});
				}

				// Effettua ulteriori azioni dopo la risposta del server
			})
			.catch((error) => {
				console.error("Errore nella chiamata POST:", error);
				// Gestisci l'errore in modo appropriato
			});
	};

	useEffect(() => {
		console.log(state.messageServer, state.user.role);
		if (state.messageServer !== "" && state.user.role === "user") {
			setTimeout(function () {
				console.log("quiiiiii");
				window.location.replace("/");
			}, 1000);
		}
	}, [state.messageServer]);
	/** REGISTRATION */

	/** LOGIN */

	const setUsername = (e) => {
		dispatch({ state, type: "USERNAME", payload: e });
	};
	const setPassword = (e) => {
		dispatch({ state, type: "PASSWORD", payload: e });
	};
	function submitLogin(guest = false) {
		if (!guest) {
			if (state.checkForm === true) {
				fetch("/verifica-utente", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: username,
						password: password,
					}),
				})
					.then((response) => {
						if (response.ok) {
							console.log(response);
							return response.json(); // Converti la risposta in formato JSON
						} else {
							throw new Error("Errore nella risposta del server");
						}
					})
					.then((data) => {
						console.log(data.results);
						// Gestisci i dati ricevuti dal server

						if (data.results.length > 0) {
							dispatch({
								state,
								type: "LOGIN",
								payload: data.results,
							});
						} else {
							dispatch({
								state,
								type: "LOGIN",
								payload: false,
							});
						}
					})
					.catch((error) => {
						console.error("Errore durante la richiesta:", error);
					});
			}
		} else {
			const data = [
				{
					id_user: 0,
					nome: "Utente",
					role: "user",
				},
			];
			dispatch({
				state,
				type: "LOGIN",
				payload: data,
			});
		}
	}

	function logout() {
		dispatch({ state, type: "LOGOUT" });
	}
	/** LOGIN */

	/**UPLOAD */
	const handleUpload = () => {
		Array.from(files).map((file, idx) =>
			dispatch({
				state,
				type: "UPLOAD",
				payload: [file, fetchFileNames, setFiles],
			})
		);

		if (files[0]) {
		} else {
			alert("Seleziona almeno un file");
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleFileDrop = (event) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files).slice(0, 1);
		setFiles(files);
	};
	/**UPLOAD */

	/**FILES ON SERVER */
	function fetchFileNames() {
		dispatch({
			state,
			type: "LIST_FILES",
			payload: [setFileNames],
		});
	}

	async function deleteFiles(file) {
		try {
			let uploadlink = "/deleteFile";

			if (state.mode === "development") {
				uploadlink = "/deleteFile";
			} else if (state.mode === "production") {
				uploadlink = "/deleteFile";
			} else {
				// Gestione dell'ambiente non riconosciuto
			}
			console.log(uploadlink);
			const response = await fetch(uploadlink, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ file }),
			});

			if (response.ok) {
				console.log("File cancellato con successo.");
				// Aggiorna la lista dei file dopo la cancellazione
				fetchFileNames();
			} else {
				console.error("Errore nella richiesta:", response.status);
			}
		} catch (error) {
			console.error("Errore durante la richiesta:", error);
		}
	}
	/**FILES ON SERVER */

	useEffect(() => {
		if (localStorage.getItem("username") && localStorage.getItem("role")) {
			dispatch({ state, type: "SET_BY_LOCAL" });
		}
	}, []);

	useEffect(() => {
		if (!state.login) {
			if (password.length > 2 && username.length > 2) {
				state.checkForm = true;
			}
		}
	}, [username, password]);

	useEffect(() => {
		if (state.login && !state.errorAuth) {
			fetchFileNames();
		} else if (!state.login && state.errorAuth) {
			dispatch({ state, type: "ERROR_AUTH" });
		}
	}, [state.login, state.checkForm, state.errorAuth]);

	return (
		<>
			<AppContext.Provider
				value={{
					...state,
					handleUpload,
					fetchFileNames,
					deleteFiles,
					fileInputRef,
					fileNames,
					setFileNames,
					files,
					setFiles,
					handleDragOver,
					handleFileDrop,
					setUsername,
					setPassword,
					submitLogin,
					logout,
					setValueRegistration,
					submitFormData,
				}}>
				{children}
			</AppContext.Provider>
		</>
	);
};

const useGlobalContext = () => {
	return useContext(AppContext);
};

export { AppProvider, useGlobalContext };
