import React from "react";
import FileList from "../Components/FileList";
import FileDropArea from "../Components/FileDropArea";
import logo from "../logo.png";
import NavbarLink from "../Components/NavbarLink";

const userList = () => {
	return (
		<>
			<NavbarLink></NavbarLink>
			<div className="wrapperAdmin">
				<img src={logo} alt="Logo" className="logo"></img>
				<h1>* Lista Utenti Registrati *</h1>
				<table>
					<thead>
						<tr>
							<th>Colonna 1</th>
							<th>Colonna 2</th>
							<th>Colonna 3</th>
							<th>Colonna 4</th>
							<th>Colonna 5</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Dato 1-1</td>
							<td>Dato 1-2</td>
							<td>Dato 1-3</td>
							<td>Dato 1-4</td>
							<td>Dato 1-5</td>
						</tr>
						<tr>
							<td>Dato 2-1</td>
							<td>Dato 2-2</td>
							<td>Dato 2-3</td>
							<td>Dato 2-4</td>
							<td>Dato 2-5</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default userList;
