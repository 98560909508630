import React from "react";
import { useNavigate } from "react-router-dom";
const ErrorPage = () => {
	const navigate = useNavigate();

	return (
		<div>
			Errore pagina non trovata
			<button onClick={() => navigate("/")}>Back to home</button>
		</div>
	);
};

export default ErrorPage;
