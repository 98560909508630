import React from "react";
import { useGlobalContext } from "../Components/Context";
import logo from "../logo.png";
const Login = () => {
	const {
		checkForm,
		messageUsername,
		messagePassword,
		errorAuth,
		setUsername,
		setPassword,
		submitLogin,
	} = useGlobalContext();

	return (
		<div className="wrapperLogin">
			<img className="logo" src={logo} alt="logo" />
			<div className="form-input-wrapper">
				<label htmlFor="username">Email</label>
				<input
					type="text"
					id="username"
					onChange={(e) => setUsername(e.target.value)}></input>
				<span className="message">{messageUsername}</span>
			</div>
			<div className="form-input-wrapper">
				<label htmlFor="password">Password</label>
				<input
					type="password"
					id="password"
					onChange={(e) => setPassword(e.target.value)}></input>
				<span className="message">{messagePassword}</span>
			</div>
			<div>
				<button
					className={checkForm ? "secondary-color" : "disabled"}
					onClick={() => submitLogin()}>
					Login
				</button>
				<button
					className={"secondary-color skip"}
					onClick={() => submitLogin(true)}>
					Skip
				</button>
				<span className={!errorAuth ? "" : "message red"}>
					{errorAuth && "Errore di autenticazione"}
				</span>

				<div className="registration">
					<span>Ho dimenticato la password</span>
					<span
						onClick={() => {
							window.location.replace("/registration");
						}}>
						Registrati
					</span>
				</div>
			</div>
		</div>
	);
};

export default Login;
