import { MdDeleteForever } from "react-icons/md";
import { useGlobalContext } from "./Context";
function FileList() {
	const { deleteFiles, fileNames } = useGlobalContext();

	if (!fileNames)
		return (
			<>
				<div className="listOnServer">
					<h3>* ELENCO FILE NEL SERVER *</h3>

					<ul></ul>
				</div>
			</>
		);
	else {
		return (
			<>
				<div className="listOnServer">
					<h3>* ELENCO FILE NEL SERVER *</h3>

					<ul>
						{fileNames?.map((fileName, index) => (
							<li key={index}>
								{fileName}{" "}
								<span onClick={() => deleteFiles(fileName)}>
									{" "}
									<MdDeleteForever />
								</span>{" "}
							</li>
						))}
					</ul>
				</div>
			</>
		);
	}
}
export default FileList;
// MdDeleteForever
