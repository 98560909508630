import React from "react";
import logo from "../logo.png";
import NavbarLink from "../Components/NavbarLink";
import { useGlobalContext } from "../Components/Context";
const FormRegistration = () => {
	const {
		setValueRegistration,
		regUser,
		messageError,
		regiStrationValid,
		submitFormData,
		messageServer,
	} = useGlobalContext();

	return (
		<>
			<NavbarLink></NavbarLink>
			<div className="wrapperAdmin">
				<img src={logo} alt="Logo" className="logo"></img>
				<div className="flexWrapper">
					<div className="form-input-wrapper">
						<label htmlFor="Name">Nome</label>
						<input
							type="text"
							id="Name"
							name="nome"
							value={regUser.nome}
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className="message red">{messageError.nome}</span>
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="cognome">Cognome</label>
						<input
							type="text"
							id="Cognome"
							name="cognome"
							value={regUser.cognome}
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className="message red">{messageError.cognome}</span>
					</div>
				</div>
				<div className="flexWrapper">
					<div className="form-input-wrapper">
						<label htmlFor="Email">Email</label>
						<input
							type="email"
							id="Email"
							name="email"
							value={regUser.email}
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className="message red">{messageError.email}</span>
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="cognome">Cellulare</label>
						<input
							type="text"
							id="Cellulare"
							value={regUser.cellulare}
							name="cellulare"
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className={"message red"}>{messageError.cellulare}</span>
					</div>
				</div>
				<div className="flexWrapper">
					<div className="form-input-wrapper">
						<label htmlFor="Password">Password</label>
						<input
							type="Password"
							id="Password"
							name="password"
							value={regUser.password}
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className="message red">{messageError.password}</span>
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="ConfirmPassword">Conferma Password</label>
						<input
							type="text"
							id="ConfirmPassword"
							name="confPass"
							value={regUser.confPass}
							onChange={(e) => setValueRegistration(e)}
						></input>
						<span className="message red">{messageError.confPass}</span>
					</div>
				</div>
				<div className="flexWrapperVert">
					<button
						className={regiStrationValid ? "secondary-color" : "disabled"}
						onClick={() => submitFormData()}
					>
						Registrati
					</button>
					<button
						className="third-color"
						onClick={() => window.location.replace("/login")}
					>
						Torna indietro
					</button>
				</div>
				<p className="messageServer">{messageServer}</p>
			</div>
		</>
	);
};

export default FormRegistration;
