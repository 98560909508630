import { useGlobalContext } from "./Context";
const Draganddrop = () => {
	const {
		handleFileDrop,
		handleDragOver,
		files,
		setFiles,
		fileInputRef,
		handleUpload,
	} = useGlobalContext();

	if (files) {
		return (
			<>
				<div className="listFiles">
					<ul>
						{Array.from(files).map((file, idx) => (
							<li key={idx}>{file.name}</li>
						))}
					</ul>
				</div>
				<div className="action">
					<button
						onClick={() => {
							setFiles(null);
						}}
					>
						Cancel
					</button>
					<button onClick={() => handleUpload()}>Carica</button>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div
					className="upload"
					onDrop={handleFileDrop}
					onDragOver={handleDragOver}
				>
					<label htmlFor="images" className="drop-container">
						<span className="drop-title">
							Fai il Drag and Drop del documento
						</span>
						oppure seleziona il tuo files
						<input
							type="file"
							// multiple
							id="images"
							onChange={(event) => {
								setFiles(event.target.files);
							}}
							ref={fileInputRef}
							required
						/>
					</label>
				</div>
			</>
		);
	}
};

export default Draganddrop;
